@function random-color() {
  $value: "black";
  @if random(5) == 1 {
   $value: "#DAF7A6";
  }
  @else if random(5) == 2{
    $value: "#FFC300";
  }
  @else if random(5) == 3{
    $value: "#FF5733";
  }
  @else if random(5) == 4{
    $value: "#82E0AA";
  }
  @else {
    $value: "#85C1E9";
  }
  @return unquote($value);
}

// n is number of stars required
@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(3000)}px #fff";

  @for $i from 2 through $n {
    $value: "#{$value} , #{random(3000)}px #{random(3000)}px #fff";
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

.parallax {
  #stars1 {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-small;
    animation: animStar 150s linear infinite;
  
    &:after {
      content: " ";
      position: absolute;
      top: 2000px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: transparent;
      box-shadow: $shadows-small;
    }
  }
  
  #stars2 {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-medium;
    animation: animStar 100s linear infinite;
  
    &:after {
      content: " ";
      position: absolute;
      top: 2000px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: transparent;
      box-shadow: $shadows-medium;
    }
  }
  
  #stars3 {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-big;
    animation: animStar 50s linear infinite;
  
    &:after {
      content: " ";
      position: absolute;
      top: 2000px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: transparent;
      box-shadow: $shadows-big;
    }
  }
  
  @keyframes animStar {
    from {
      transform: translateY(-2000px);
    }
  
    to {
      transform: translateY(0px);
    }
  }
}
